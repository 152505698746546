import React from "react";
import HoptechApp from "../scenes/hopTechApp";
import SEO from "../components/seo";

export default () => {
  return (
    <>
      <SEO title={"Hoptechnic App"} />
      <HoptechApp />
    </>
  );
};
