import React from "react";
import Overview from "./components/overview";
import Features from "./components/features";
import Video from "./components/video";
import styles from "./HopTechApp.module.scss";


const HopTechApp = () => {
  return (
      <div className={styles.wrap}>
        <div className={styles.column}>
          <Overview />
        </div>
        <div className={styles.column}>
          <Features />
        </div>
      </div>
  );
};

export default HopTechApp;
