import React from "react";
import styles from "./Overview.module.scss";

const Overview = () => {
  return (
    <>
      <div className={styles.pageTitle}>App overview</div>
      <div className={styles.content}>
        <div className={styles.textWrap}>
          <h3 className={styles.subtitle}>
            <span className={styles.highlighted}>HopTechnics™</span> is a{" "}
            <span className={styles.highlighted}>first-of-its kind</span> hop
            selection <span className={styles.highlighted}>tool</span> that
            brings the{" "}
            <span className={styles.highlighted}>power of choice</span> to the{" "}
            <span className={styles.highlighted}>craft brewer</span>.
          </h3>
          <p className={styles.text}>
            A new way to brew and a new way to sell, HopTechnics® is an
            interactive hop flavor profile selector.
          </p>
          <p className={styles.text}>
            Using proprietary software, HopTechnics™ makes spot inventory
            searchable by more than just crop year. Craft brewers can look for
            and select. available hop lots based on aroma profile instead of
            just brewing values.
          </p>
          <p className={`${styles.text} ${styles.highlighted}`}>
            HopTechnics®. The science of hops at your fingertips.™
          </p>
        </div>
      </div>
    </>
  );
};

export default Overview;
