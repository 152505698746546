import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      codeActivationImage: file(
        relativePath: { eq: "images/hoptechnic-app/global-icon.png" }
      ) {
        publicURL
      }
      flavorProfileSelectorImage: file(
        relativePath: {
          eq: "images/hoptechnic-app/flavor-profile-selector.svg"
        }
      ) {
        publicURL
      }
      hopInventoryImage: file(
        relativePath: { eq: "images/hoptechnic-app/hop-inventory.svg" }
      ) {
        publicURL
      }
      hopVarietySelectorImage: file(
        relativePath: { eq: "images/hoptechnic-app/hop-variety-selector.svg" }
      ) {
        publicURL
      }
      labSupportImage: file(
        relativePath: { eq: "images/hoptechnic-app/lab-support.svg" }
      ) {
        publicURL
      }
      spyderchartImage: file(
        relativePath: { eq: "images/hoptechnic-app/spyderchart.svg" }
      ) {
        publicURL
      }
    }
  `);
  return data;
};
