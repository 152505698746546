import React from "react";
import { ReactSVG } from "react-svg";
import useGetImages from "./useGetImages";
import styles from "./Features.module.scss";

const Features = () => {
  const {
    codeActivationImage,
    flavorProfileSelectorImage,
    hopInventoryImage,
    hopVarietySelectorImage,
    labSupportImage,
    spyderchartImage,
  } = useGetImages();

  const data = [
    {
      title: "Spyder Chart Database",
      iconUrl: spyderchartImage,
    },
    {
      title: "Hop Variety Selector",
      iconUrl: hopVarietySelectorImage,
    },
    {
      title: "Interactive Flavor Profile Selector",
      iconUrl: flavorProfileSelectorImage,
    },
    {
      title: "Hop Inventory Database",
      iconUrl: hopInventoryImage,
    },
    {
      title: "HopTechnic® Lab Support",
      iconUrl: labSupportImage,
    },
    {
      title: "Accessible Globally",
      iconUrl: codeActivationImage,
    },
  ];
  return (
    <>
      <div className={styles.pageTitle}>App features</div>
      <div className={styles.content}>
        <div className={styles.features}>
          {data.map(({ title, iconUrl }) => (
            <div className={styles.featureItem} key={title}>
              <div className={styles.icon}>
                <img src={iconUrl.publicURL}  className={styles.featureImage}/>
              </div>
              <div className={styles.featureTitle}>{title}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Features;
